.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 90%;
    margin: 0 auto;
    margin-top: 50px;
}

.mark {
    background-color: #007dcc;
    color: #fff;
}

.appName {
    font-size: 24px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.appAuthor {
    font-size: 18px;
    margin-bottom: 15px;
    cursor: pointer;
}

.whatDoesItDo {
    margin-bottom: 10px;
    text-align: left;
}

.appDesc {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin: auto;
    margin-top: 1rem;
}