.header {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.8rem;
    padding-right: 1.6rem;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: #171717;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 3;
}

.appName {
    font-size: 20px;
    font-weight: bold;
    font-size: 1.2rem;
    padding-left: 0.3rem;
    flex: 1;
}

.actionIconsAdd img, .actionIconsMenu img{
    margin-left: 12px;
}

.actionIconsAdd img:hover, .actionIconsMenu img:hover,
.actionIconsAdd img:focus, .actionIconsMenu img:focus{
    cursor: pointer;
    transform: scale(1.1);
}

.actionIconsAdd img{
    width: 35px;
    height: 35px;
}

.actionIconsMenu img{
    width: 30px;
    height: 30px;
}

.mainContent {
    padding: 0.5rem 0.8rem;
}

.noAccounts {
    padding-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.noAccounts img {
    width: 150px;
    height: 150px;
}

.noAccountsSubText {
    padding-top: 2rem;
    max-width: 50%;
}

.line {
    position: absolute;
    top: 55px;
    right: 35px;
}