.privacy {
    padding: 20px;
    max-width: 90%;
    margin: 0 auto;
}

.infoSpan {
    font-size: 1.4rem;
    color: #fff;
}

.privacyPolicy {
    max-width: 90%;
    margin: 0 auto;
}

.privacyPolicyTitle {
    font-size: 2rem;
    margin: 20px 0;
    color: #fff;
}