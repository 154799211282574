.displayBar {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.8rem;
    margin: 0.5rem 0.3rem;
    border: 1px solid #ffffff17;
    border-radius: 12px;
    background-color: #0070f3;
}

.left {
    display: grid;
    place-items: center;
    min-width: 4ch;
}

.right {
    padding-left: 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.label {
    font-size: 1.1rem;
    margin-bottom: 0.3rem;
}

.description {
    font-size: 0.9rem;
}