.import {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 90%;
	margin: 1rem auto 0;
    margin-bottom: 2rem;
}

.encrypt {
	margin-top: 1rem;
}

.inputField {
	display: grid;
	place-items: center;
}

.inputField input[type="text"] {
	width: 85%;
	padding: 0.5rem 0.8rem;
	border: none;
	border-radius: 9px;
	background-color: #ffffff17;
	color: white;
	font-size: 1.2rem;
	margin-bottom: 0.5rem;
	outline: 1px solid #000000;
}

.inputField input[type="text"]:hover , .inputField input[type="text"]:focus {
	outline: 1px solid #ffffff6e;
}

.button {
	padding: 0.5rem 0.8rem;
	border-radius: 8px;
	font-size: 1.2rem;
	color: #fff;
	background-color: #0070f3;
	border: 0;
	outline: none;
	margin-top: 1rem;
	-webkit-box-shadow: 4px 4px 3px 1px #b0b0b01f;
	box-shadow: 4px 4px 3px 1px #b0b0b01f;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	margin-left: auto;
	margin-right: auto;
	cursor: pointer;
	background-position: center;
	-webkit-transition: background 0.8s;
	-o-transition: background 0.8s;
	transition: background 0.8s;
}

.button:hover {
	background-color: #005ac2;
	background: #005ac2 -o-radial-gradient(circle, transparent 1%, #005ac2 1%) center/15000%;
	background: #005ac2 radial-gradient(circle, transparent 1%, #005ac2 1%)
		center/15000%;
}

.button:active {
	background-color: #0070f3;
	background-size: 100%;
	-webkit-transition: background 0s;
	-o-transition: background 0s;
	transition: background 0s;
}

.infoSpan {
	font-size: 1.1rem;
	color: #ffffff8a;
	margin-bottom: 1rem;
	max-width: 85%;
	text-align: center;
}

.importantText, .errorText {
	width: 85%;
    min-height: 70px;
	color: #ffffff;
	font-size: 1.2rem;
	margin-bottom: 1rem;
	text-align: center;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
}

.errorText {
    color: #f1c40f;
}

.dropContainer {
    margin-bottom: 1.5rem;
	position: relative;
	display: flex;
	gap: 10px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 90px;
	padding: 20px;
	border-radius: 10px;
	border: 2px dashed #555;
	color: #ffffff8a;
	cursor: pointer;
}

.dropContainer:hover {
	background: #eee;
	border-color: #111;
    color: #000;
}

.dropContainer:hover .dropTitle {
	color: #000;
}

.dropTitle {
	color: #ffffff8a;
	font-size: 20px;
	font-weight: bold;
	text-align: center;
	padding-bottom: 0.5rem;
}

input[type="file"] {
    font-family: "Inter";
	width: 250px;
	max-width: 100%;
	color: #000;
	padding: 5px;
	background: #fff;
	border-radius: 10px;
	border: 1px solid #555;
}

input[type="file"]::file-selector-button {
    font-family: "Inter";
	margin-right: 20px;
	border: none;
	background: #0070f3;
	padding: 10px 20px;
	border-radius: 10px;
	color: #fff;
	cursor: pointer;
	transition: background 0.2s ease-in-out;
}

input[type="file"]::file-selector-button:hover {
    font-family: "Inter";
	background-color: #005ac2;
	background: #005ac2 -o-radial-gradient(circle, transparent 1%, #005ac2 1%) center/15000%;
	background: #005ac2 radial-gradient(circle, transparent 1%, #005ac2 1%)
		center/15000%;
}
