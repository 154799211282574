/* inter-regular - latin */
@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	src: url("./assets/inter-v12-latin-regular.eot"); /* IE9 Compat Modes */
	src: local(""),
		url("./assets/inter-v12-latin-regular.eot?#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */ url("./assets/inter-v12-latin-regular.woff2")
			format("woff2"),
		/* Super Modern Browsers */ url("./assets/inter-v12-latin-regular.woff")
			format("woff"),
		/* Modern Browsers */ url("./assets/inter-v12-latin-regular.ttf")
			format("truetype"),
		/* Safari, Android, iOS */
			url("./assets/inter-v12-latin-regular.svg#Inter") format("svg"); /* Legacy iOS */
	font-display: swap;
}

body {
	margin: 0;
	font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
		"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #000;
	color: #fff;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
