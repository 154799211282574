.icon {
    display: grid;
    place-items: center;
    margin-left: 0.5rem;
}

.icon img {
    padding: 0.5rem;
}

.menuCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.5rem 0;
    min-height: 80px;
}

.menuCard:hover, .menuCard:focus {
    background-color: #1a1a1a;
    border-radius: 0.5rem;
    cursor: pointer;
}


.menuContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.5rem;
    align-items: flex-start;
}

.menuContentTitle {
    font-size: 1.5rem;
}

.menuContentDescription {
    font-size: 0.8rem;
}

.menuCardNavigation {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    margin-right: 0.5rem;
}