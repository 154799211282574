.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.infoSpan {
    padding-top: 1.5rem;
    font-size: 1.2rem;
    padding-bottom: 2rem;
    text-align: center;
    max-width: 75vw;
}

.errorSpan {
    color: #f1c40f;
}

.enterCode {
    font-size: 1.1rem;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 1.3rem;
    cursor: pointer;
}

.enterCode img {
    height: 25px;
    width: 25px;
    margin-left: 0.5rem;
}